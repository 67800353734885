import type { ReduxState } from '@shared/redux';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectSelf = (state: ReduxState) => state.auth;

export const selectTour = createDraftSafeSelector(
  selectSelf,
  (state) => state.tour,
);

export const selectMessage = createDraftSafeSelector(
  selectSelf,
  (state) => state.message,
);

export const selectAuthUser = createDraftSafeSelector(
  selectSelf,
  (state) => state.authUser,
);

export const selectAuthUserDeactivated = createDraftSafeSelector(
  selectAuthUser,
  (state) => !!state?.deactivated,
);

export const selectAuthUserLinkedAccounts = createDraftSafeSelector(
  selectAuthUser,
  (state) => state?.linked_accounts,
);

export const selectAuthUserMigratedAt = createDraftSafeSelector(
  selectAuthUser,
  (state) => state?.migrated_at,
);

export const selectAccessToken = createDraftSafeSelector(
  selectSelf,
  (state) => state.access_token,
);

export const selectOnboardingModal = createDraftSafeSelector(
  selectSelf,
  (state) => state.onboardingModal,
);

export const selectVerificationModal = createDraftSafeSelector(
  selectSelf,
  (state) => state.verificationModal,
);

export const selectTourStep = createDraftSafeSelector(
  selectTour,
  (state) => state.step,
);

export const selectTourIsOpen = createDraftSafeSelector(
  selectTour,
  (state) => state.open,
);

export const selectOnboarding = createDraftSafeSelector(
  selectAuthUser,
  (user) => user?.onboarding,
);

export const selectHolidayMode = createDraftSafeSelector(
  selectOnboarding,
  (user) => {
    if (user?.holiday_mode === undefined) {
      return true;
    }

    return user?.holiday_mode;
  },
);

export const selectFirstTour = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.[1],
);

export const selectSecondTour = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.[2],
);

export const selectThirdTour = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.[3],
);

export const selectAuthUserNotify = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.notify,
);

export const selectVerificationHasSent = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.verification_sent === 1,
);

export const selectGeneralInfo = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.general_info,
);

export const selectBasics = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.basics,
);

export const selectComponentsIsSelected = createDraftSafeSelector(
  selectBasics,
  (onboarding) => onboarding?.profile === 1,
);

export const selectTallyIsClosed = createDraftSafeSelector(
  selectOnboarding,
  (onboarding) => onboarding?.show_survey === 1,
);

export const selectCategoriesHasSelected = createDraftSafeSelector(
  selectGeneralInfo,
  (onboarding) => onboarding?.categories === 1,
);

export const selectGetStartedCompletedCount = createDraftSafeSelector(
  selectVerificationHasSent,
  selectCategoriesHasSelected,
  selectComponentsIsSelected,
  (verificationHasSent, categoriesHasSelected, componentsIsSelected) =>
    1 +
    Number(verificationHasSent) +
    Number(categoriesHasSelected) +
    Number(componentsIsSelected),
);

export const selectFirstTourCompleted = createDraftSafeSelector(
  selectFirstTour,
  (firstTour) =>
    firstTour?.bio === 1 &&
    firstTour?.categories === 1 &&
    firstTour?.social_links === 1,
);

export const selectSecondTourCompleted = createDraftSafeSelector(
  selectSecondTour,
  (secondTour) => secondTour?.theme === 1 && secondTour?.components === 1,
);

export const selectThirdTourCompleted = createDraftSafeSelector(
  selectThirdTour,
  (thirdTour) =>
    thirdTour?.arrange_cards === 1 && thirdTour?.resize_cards === 1,
);

export const selectIsFreePlan = createDraftSafeSelector(
  selectAuthUser,
  (user) => user?.current_plan === 'free',
);

export const selectShouldFetch = createDraftSafeSelector(
  selectAuthUser,
  (user) => user?.shouldFetch,
);

export const selectAuthUserHandle = createDraftSafeSelector(
  selectAuthUser,
  (authUser) => authUser?.handle,
);

export const selectAuthUserEmail = createDraftSafeSelector(
  selectAuthUser,
  (authUser) => authUser?.email,
);

export const selectTikTokUsername = createDraftSafeSelector(
  selectAuthUser,
  (authUser) => authUser?.tiktok,
);

export const selectYouTubeUsername = createDraftSafeSelector(
  selectAuthUser,
  (authUser) => authUser?.youtube,
);

export const selectRegistrationStep = createDraftSafeSelector(
  selectAuthUser,
  (user) => user?.step,
);

export const selectInstagramUsername = createDraftSafeSelector(
  selectAuthUser,
  (authUser) => authUser?.instagram,
);

export const selectVerificationStatus = createDraftSafeSelector(
  selectAuthUser,
  (authUser) => authUser?.vm_verify,
);
