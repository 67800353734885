import { Builder } from '@shared/types/builder';
import type { ReduxState } from '@shared/redux';
import { Root } from '@shared/types/builder/fontGroups';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { FiniteStates } from '@shared/constants/finiteStates';
import { ComponentNames } from '@shared/constants/componentNames';
import {
  PLATFORM_ALL,
  PLATFORM_INSTAGRAM,
  PLATFORM_TIKTOK,
  PLATFORM_YOUTUBE,
} from '@shared/constants';

const selectBuilderReducer = (state: ReduxState) => state.builder;

export const selectCurrentMediakit = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.currentMediakit,
);

export const selectCurrentMediakitId = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.currentMediakit?.id,
);

export const selectVisits = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.visits,
);

export const selectCreatingQueryStatus = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.creatingQueryStatus,
);

export const selectPreviewMode = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.previewMode,
);

export const selectBuilderPreviewMode = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.builderPreviewMode,
);
export const selectBuilderPreviewIsMobile = createDraftSafeSelector(
  selectBuilderPreviewMode,
  (previewMode) => previewMode === 'mobile',
);

export const selectMediakitTheme = createDraftSafeSelector(
  selectCurrentMediakit,
  (mediakit) => mediakit.theme,
);

export const selectMediakitThemeFonts = createDraftSafeSelector(
  selectMediakitTheme,
  (theme) => theme?.fonts,
);

export const selectColors = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.colors,
);

export const selectThemeDrawerHeight = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.themeDrawerHeight,
);

export const selectComponentsDrawerHeight = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.componentsDrawerHeight,
);

export const selectTags = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.tags,
);

export const selectShareModalIsOpen = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.shareModalIsOpen,
);

export const selectTagsArray = createDraftSafeSelector(selectTags, (tags) => {
  const tagsArray = Object.keys(tags || {}).reduce(
    (acc, value) => {
      acc.push({
        id: Number(value),
        name: (tags as any)[value],
      });
      return acc;
    },
    [] as { id: number; name: string }[],
  );
  tagsArray.unshift({ id: 0, name: 'All' });

  return tagsArray;
});

export const selectIsSavingMediakit = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.isSavingMediakit,
);

export const selectLastUpdatedAt = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.lastUpdatedAt,
);

export const selectInternetConnectionStatus = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.internetConnectionStatus,
);

export const selectGridLayoutBreakpoint = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.gridLayoutBreakpoint,
);

export const selectCounter = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.counter,
);

export const selectAvatarIsLoading = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.avatarState === FiniteStates.PENDING,
);

export const selectUploadImageStates = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.uploadImageStates,
);

export const selectSelectedFilter = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.selectedFilter,
);

export const selectThemeFilter = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.themeFilter,
);

export const selectThemeColors = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.theme.colors,
);

export const selectAllLayout = createDraftSafeSelector(
  selectCurrentMediakit,
  (state) => state.allLayouts,
);

export const selectCurrentLayout = createDraftSafeSelector(
  selectCurrentMediakit,
  selectGridLayoutBreakpoint,
  (state, breakpoint) => state.allLayouts[breakpoint],
);

export const selectProfile = createDraftSafeSelector(
  selectCurrentMediakit,
  (state) => state.profile,
);

export const selectLocation = createDraftSafeSelector(
  selectProfile,
  (state) => state.location,
);

export const selectSocialLinks = createDraftSafeSelector(
  selectProfile,
  (state) => state.social_links,
);

export const selectAvatar = createDraftSafeSelector(
  selectProfile,
  (state) => state.avatar,
);

export const selectName = createDraftSafeSelector(
  selectProfile,
  (state) => state.name,
);

export const selectBio = createDraftSafeSelector(
  selectProfile,
  (state) => state.bio,
);

export const selectActionButton = createDraftSafeSelector(
  selectProfile,
  (state) => state.cta || '',
);

export const selectSelectedCategories = createDraftSafeSelector(
  selectProfile,
  (state) => state.categories || [],
);

export const selectDrawerIsOpen = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.drawerIsOpen,
);

export const selectThemeDrawerIsOpen = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.themeDrawerIsOpen,
);

export const selectGridLayoutProps = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.gridLayoutProps,
);

export const selectActiveItem = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.activeItem,
);

export const selectAllElementsAreSelected = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.allElementsAreSelected,
);

export const selectComponents = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.components as Builder.Components.Items,
);

export const selectReportStats = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.reportStats,
);

export const selectBgImages = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.bgImages,
);

export const selectFontGroups = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.fontGroups,
);

export const selectFontGroupArray = createDraftSafeSelector(
  selectFontGroups,
  (fontGroups) => {
    return Object.values<Root>(fontGroups || {}).sort(
      (a, b) => a.order - b.order,
    );
  },
);

export const selectExtraData = createDraftSafeSelector(
  selectBuilderReducer,
  (state) => state.extraData,
);

export const selectInstagramComponents = createDraftSafeSelector(
  selectComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components).filter(
      ({ platform }) => platform === PLATFORM_INSTAGRAM,
    ),
);

export const selectInstagramAccountComponents = createDraftSafeSelector(
  selectInstagramComponents,
  (components) => {
    const c = Object.values<Builder.Components.Component>(components).sort(
      (a, b) => {
        if (b.name === ComponentNames.instagram_profile_basic) {
          return 0;
        }

        if (a.name === ComponentNames.instagram_profile) {
          return 1;
        }

        if (
          [
            ComponentNames.instagram_likes_growth,
            ComponentNames.instagram_followers_growth,
          ].includes(b.name)
        ) {
          return -1;
        }

        return 0;
      },
    );

    [c[0], c[1]] = [c[1], c[0]];

    return c;
  },
);

export const selectInstagramAudienceComponents = createDraftSafeSelector(
  selectInstagramComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components).filter(
      ({ name }) => {
        return [
          ComponentNames.instagram_top_cities,
          ComponentNames.instagram_top_countries,
          ComponentNames.instagram_audience_genders,
          ComponentNames.instagram_audience_genders_per_age,
        ].includes(name);
      },
    ),
);

export const selectYouTubeComponents = createDraftSafeSelector(
  selectComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components).filter(
      ({ platform }) => {
        return platform === PLATFORM_YOUTUBE;
      },
    ),
);

export const selectYouTubeAccountComponents = createDraftSafeSelector(
  selectYouTubeComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components)
      // .filter(({ name }) => {
      //   return ![
      //     ComponentNames.youtube_top_countries,
      //     ComponentNames.youtube_audience_genders,
      //     ComponentNames.youtube_audience_genders_per_age,
      //   ].includes(name);
      // })
      .sort((a, b) => {
        if (a.name === ComponentNames.youtube_profile) {
          return 1;
        }

        if (
          [
            ComponentNames.youtube_views_growth,
            ComponentNames.youtube_followers_growth,
          ].includes(b.name)
        ) {
          return -1;
        }

        return 0;
      }),
);

export const selectYouTubeAudienceComponents = createDraftSafeSelector(
  selectYouTubeComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components).filter(
      ({ name }) => {
        return [
          ComponentNames.youtube_top_countries,
          ComponentNames.youtube_audience_genders,
          ComponentNames.youtube_audience_genders_per_age,
        ].includes(name);
      },
    ),
);

export const selectTikTokComponents = createDraftSafeSelector(
  selectComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components).filter(
      ({ platform }) => {
        return platform === PLATFORM_TIKTOK;
      },
    ),
);

export const selectTikTokAccountComponents = createDraftSafeSelector(
  selectTikTokComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components)
      // .filter(({ name }) => {
      //   return ![
      //     ComponentNames.tiktok_top_countries,
      //     ComponentNames.tiktok_audience_genders,
      //     ComponentNames.tiktok_audience_genders_per_age,
      //   ].includes(name);
      // })
      .sort((a, b) => {
        if (a.name === ComponentNames.tiktok_profile) {
          return 1;
        }

        if (
          [
            ComponentNames.tiktok_likes_growth,
            ComponentNames.tiktok_followers_growth,
          ].includes(b.name)
        ) {
          return -1;
        }

        return 0;
      }),
);

export const selectTikTokAudienceComponents = createDraftSafeSelector(
  selectTikTokComponents,
  (components) =>
    Object.values<Builder.Components.Component>(components).filter(
      ({ name }) => {
        return [
          ComponentNames.tiktok_top_countries,
          ComponentNames.tiktok_audience_genders,
          ComponentNames.tiktok_audience_genders_per_age,
        ].includes(name);
      },
    ),
);

export const selectBasicComponents = createDraftSafeSelector(
  selectComponents,
  (components) => {
    return Object.values<Builder.Components.Component>(components)
      .filter(({ platform }) => platform === PLATFORM_ALL)
      .sort((a) => {
        if (
          [
            ComponentNames.basics_service_list,
            ComponentNames.basics_link,
            ComponentNames.basics_image,
            ComponentNames.basics_info_card,
          ].includes(a.name)
        ) {
          return -1;
        }

        return 0;
      });
  },
);
