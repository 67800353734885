export enum ComponentNames {
  tiktok_profile = 'tk_profile',
  youtube_profile = 'yt_profile',
  instagram_profile = 'ig_profile',

  instagram_avg_views = 'ig_avg_views',
  instagram_followers = 'ig_followers',
  instagram_avg_likes = 'ig_avg_likes',
  instagram_avg_comments = 'ig_avg_comments',
  instagram_profile_basic = 'ig_profile_basic',
  instagram_engagement_rate = 'ig_engagement_rate',
  instagram_avg_reels_plays = 'ig_avg_reels_plays',
  instagram_likes_growth = 'ig_total_likes_growth',
  instagram_followers_growth = 'ig_followers_growth',
  instagram_audience_genders = 'ig_audience_genders',
  instagram_audience_genders_per_age = 'ig_audience_genders_per_age',
  instagram_top_countries = 'ig_top_countries',
  instagram_top_cities = 'ig_top_cities',

  tiktok_avg_saves = 'tk_avg_saves',
  tiktok_followers = 'tk_followers',
  tiktok_avg_views = 'tk_avg_views',
  tiktok_avg_likes = 'tk_avg_likes',
  tiktok_avg_shares = 'tk_avg_shares',
  tiktok_avg_comments = 'tk_avg_comments',
  tiktok_top_countries = 'tk_top_countries',
  tiktok_likes_growth = 'tk_total_likes_growth',
  tiktok_engagement_rate = 'tk_engagement_rate',
  tiktok_followers_growth = 'tk_followers_growth',
  tiktok_audience_genders = 'tk_audience_genders',
  tiktok_audience_genders_per_age = 'tk_audience_genders_per_age',

  youtube_followers = 'yt_followers',
  youtube_avg_views = 'yt_avg_views',
  youtube_avg_likes = 'yt_avg_likes',
  youtube_avg_comments = 'yt_avg_comments',
  youtube_views_growth = 'yt_views_growth',
  youtube_top_countries = 'yt_top_countries',
  youtube_engagement_rate = 'yt_engagement_rate',
  youtube_followers_growth = 'yt_followers_growth',
  youtube_audience_genders = 'yt_audience_genders',
  youtube_audience_genders_per_age = 'yt_audience_genders_per_age',

  basics_text = 'text',
  basics_link = 'link',
  basics_image = 'image',
  basics_video = 'video',
  basics_title = 'title',
  basics_gallery = 'gallery',
  basics_info_card = 'info_card',
  basics_service_list = 'service_list',
}
