import { api } from './api';
import { Forms } from '@shared/types/forms';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@shared/utils/transformErrorResponse';

interface UpdatePasswordRT {
  message: 'success';
}

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteAccount: builder.mutation<void, void>({
      transformErrorResponse,
      query: () => ({
        method: 'DELETE',
        url: ApiEndpoints.profileUser,
      }),
    }),
    updatePassword: builder.mutation<UpdatePasswordRT, Forms.ChangePassword>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.updatePassword,
      }),
    }),
    activateAccount: builder.mutation<any, void>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.activateUser,
      }),
    }),
    deactivateAccount: builder.mutation<any, void>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.deactivateUser,
      }),
    }),
  }),
});

export const {
  endpoints,
  useDeleteAccountMutation,
  useUpdatePasswordMutation,
  useActivateAccountMutation,
  useDeactivateAccountMutation,
} = accountApi;
